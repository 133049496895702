import { graphql } from 'gatsby';
import React from 'react';

import Contact from '../components/common/footer/contact/Contact';
import StandardSEO from '../components/common/seo/StandardSEO';
import { Form, TrustedBrandIcons } from '../components/PageComps/CommonComps';
import FAQs from '../components/PageComps/FAQsPageComps/FAQs';
import { getFormData } from '../utils/utils';

const ContactUs = ({ data }) => {
  const formData = getFormData({ data });
  return (
    <>
      <Contact />

      <Form data={formData} />

      <FAQs
        data={data.sanityFaqsPage.faqs}
        title={data.sanityFaqsPage.pageHead.subTitle}
      />

      <TrustedBrandIcons data={data.sanityCommonContent.trustedBy} />
    </>
  );
};

export default ContactUs;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityContactUsPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityContactUsPage(language: { eq: $language }) {
      seo {
        title
        description
        name
        type
      }
    }
    sanityHomePage(language: { eq: $language }) {
      pageHead {
        contactForm {
          title
          subTitle
          demoFormFields {
            key
            label
            required
            multiline
          }
        }
        referralOptions {
          referralOptionsList {
            optionName
            showOption
          }
        }
      }
    }
    sanityFaqsPage(language: { eq: $language }) {
      pageHead {
        subTitle
      }
      faqs {
        title
        text
        category
      }
    }
    sanityCommonContent(language: { eq: $language }) {
      trustedBy {
        title
      }
    }
  }
`;

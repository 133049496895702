import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Link, Stack } from '@mui/material';

import Body from '../../body/Body';
import styles from '../../styling/styles';
import { MainText, SubText } from '../../texts';

const iconStyle = {
  backgroundColor: styles.white,
  fontSize: 60,
  borderRadius: 20,
  padding: 1.5,
  color: styles.deepGreen,
  ':hover': {
    backgroundColor: styles.lightGreen,
  },
};

const contacts = [
  // {
  //   icon: PhoneIcon,
  //   category: 'Phone',
  //   name: '+44700000000',
  //   link: null,
  // },
  {
    icon: MailIcon,
    category: 'Email',
    link: 'mailto:contact@fangocloud.co.uk',
  },
  {
    icon: LocationOnIcon,
    category: 'Address',
    link: 'https://www.google.com/maps/place/FanGo+Ltd/@53.8481118,-0.4128775,17z/data=!3m1!4b1!4m6!3m5!1s0x4878c7e810e4dfa1:0xb8f11aa38e8c06ad!8m2!3d53.8481118!4d-0.4103026!16s%2Fg%2F11nxr1fjp_?entry=ttu',
  },
];

const Contact = () => {
  const data = useStaticQuery(graphql`
    {
      sanityContactUsPage(_id: { eq: "contactUsPage" }) {
        phone
        email
        address
      }
    }
  `);

  console.log(data);

  const { sanityContactUsPage } = data;

  const { phone, email, address } = sanityContactUsPage;

  return (
    <Body backgroundColor="transparent">
      <MainText
        textAlignment={styles.alignStyleCCCC}
        value="Contact details"
        textVariant="h1"
        marginY={styles.allTen}
      />

      <Box
        sx={{
          display: { md: 'grid' },
          gridTemplateColumns: '2fr 2fr',
          gap: 3,
        }}
      >
        {contacts.map((contact, index) => (
          <Box
            key={index}
            sx={{
              mb: { xs: 5, md: undefined },
              px: { xs: 5, md: undefined },
            }}
          >
            <Link href={contact.link} underline="none">
              <Stack
                direction="column"
                spacing={2}
                alignItems="center"
                height="100%"
              >
                <contact.icon sx={iconStyle} />
                <Box sx={{ py: 0.5, px: 1 }}>
                  <SubText
                    variant="subtitle1"
                    value={contact.category}
                    marginY={styles.allOne}
                    textAlignment={styles.alignStyleCCCC}
                    fontWeight={700}
                  />
                  {/* {contact.category === 'Phone' && (
                    <SubText
                      variant="subtitle1"
                      value={phone}
                      marginY={styles.allZero}
                      textAlignment={styles.alignStyleCCCC}
                    />
                  )} */}

                  {contact.category === 'Email' && (
                    <SubText
                      variant="subtitle1"
                      value={email}
                      marginY={styles.allZero}
                      textAlignment={styles.alignStyleCCCC}
                    />
                  )}

                  {contact.category === 'Address' && (
                    <SubText
                      variant="subtitle1"
                      value={address}
                      marginY={styles.allZero}
                      textAlignment={styles.alignStyleCCCC}
                    />
                  )}
                </Box>
              </Stack>
            </Link>
          </Box>
        ))}
      </Box>
    </Body>
  );
};

export default Contact;
